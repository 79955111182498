import React, { useState } from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import classNames from 'classnames';
import { ValidationError } from '../../../components';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';

import InfoIcon from '../../../assets/icons/info-circle.png';

import css from './FieldDropdownSelect.module.css';

function InfoModal(props) {
  const { isOpen, onClose, modalData } = props;
  const { title, infoBox, headers, options } = modalData;

  return isOpen ? (
    <ModalComponent onClose={onClose}>
      <h4 className={css.frameSizeModalTitle}>{title}</h4>
      <span className={css.frameSizeModalInfoBox}>{infoBox}</span>
      <table className={css.frameSizeTable}>
        <thead>
          <tr className={css.frameSizeTableName}>
            {headers.map((header, index) => (
              <th key={`header_${index}`} className={css.frameSizeTableHead}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {options.map((option, index) => (
            <tr key={`option_${index}`} className={css.frameSizeTableOptionWrapper}>
              {option.map((o, i) => (
                <td key={`option_value_${i}`} className={css.frameSizeTableOption}>
                  {o}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </ModalComponent>
  ) : (
    <div />
  );
}

export function ReactSelectAdapter({
  onChangeCallback,
  input,
  options,
  translatableLabels,
  translationGroup,
  intl,
  ...rest
}) {
  const translateOptions = () =>
    options.map(option => {
      const label =
        translationGroup !== undefined
          ? intl.formatMessage({ id: `${translationGroup}.${option.key}` })
          : intl.formatMessage({ id: option.label });

      return { ...option, label };
    });

  const handleChange = option => {
    onChangeCallback && onChangeCallback(option.value);
    input.onChange(option.value);
  };

  options = translatableLabels ? translateOptions() : options;

  return (
    <Select
      {...input}
      {...rest}
      options={options}
      searchable
      value={
        options
          ? input.value?.length === 0 || input.value === undefined
            ? ''
            : options.find(
                option => option.value === input.value.toString() || [input.value].flat().includes(option.value)
              )
          : ''
      }
      onChange={handleChange}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: '48px',
          fontSize: '16px',
          borderColor: '#D1D5DB',
          fontWeight: '400',
          borderRadius: '4px',
          '&:hover': {
            borderColor: '#235641',
          },
          '::placeholder': {
            color: '#6C757D',
          },
        }),
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#D1D5DB',
          primary: '#235641',
        },
      })}
    />
  );
}

function FieldDropdownSelectComponent(props) {
  const { rootClassName, meta, label, isMandatory, modalData, id, hintText, input } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = () => {
    document.body.style.overflow = 'hidden';
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    document.body.style.overflow = 'unset';
    setIsModalOpen(false);
  };

  return (
    <div className={classNames(css.root, rootClassName)}>
      <div className={css.labelWrapper}>
        {label ? (
          isMandatory ? (
            <label className={css.label} htmlFor={id}>
              {label} <span className={css.mandatoryComponent}>*</span>
            </label>
          ) : (
            <label className={css.label} htmlFor={id}>
              {label}
            </label>
          )
        ) : null}
        {modalData ? <img className={css.infoIcon} src={InfoIcon} onClick={onOpenModal} /> : null}
      </div>
      {hintText ? (
        <div className={css.hintWrapper}>
          <span className={css.hintText}>{hintText}</span>
        </div>
      ) : null}
      <ReactSelectAdapter {...props} />
      <ValidationError fieldMeta={meta} />
      {modalData ? <InfoModal isOpen={isModalOpen} onClose={onCloseModal} modalData={modalData} /> : null}
    </div>
  );
}

function FieldDropdownSelect(props) {
  return <Field component={FieldDropdownSelectComponent} {...props} />;
}

export default FieldDropdownSelect;
